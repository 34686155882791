import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import PrimeVue from 'primevue/config'
import VueDOMPurifyHTML from 'vue-dompurify-html'
import Tooltip from 'primevue/tooltip'
import ConfirmationService from 'primevue/confirmationservice'

createApp(App)
.use(router)
.use(VueDOMPurifyHTML)
.use(PrimeVue)
.use(ConfirmationService)
.directive('tooltip', Tooltip)
.mount('#app')

