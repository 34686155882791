import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/career',
    name: 'career',
    component: () => import('../views/CareerView.vue')
  },
  {
    path: '/bar-exam-prep-assistant',
    name: 'Bar Examp Prep Assistant',
    component: () => import('../views/BarExamPrepAssistant.vue')
  },
  {
    path: '/aig-demo',
    name: 'aig',
    component: () => import('../views/aig-demo.vue')
  },
  {
    path: '/adf-prototype',
    name: 'data',
    component: () => import('../views/adf-prototype.vue')
  },
  {
    path: '/ae-classifier',
    name: 'AE Classifier',
    component: () => import('../views/AE-Classifier.vue')
  },
  {
    path: '/Paryay/Result/:id',
    name: 'ParyayResult',
    component: () => import('../views/ParyayResult.vue')
  },
  {
    path: '/paryay',
    name: 'paryay',
    component: () => import('../views/paryay.vue')
  },
  {
    path: '/natural_lang_query',
    name: 'Natural Languge Query',
    component: () => import('../views/NaturalLanguageQuery.vue')
  },
  {
    path: '/yt_summarizer',
    name: 'Youtube Summarizer',
    component: () => import('../views/YoutubeSummarizer.vue')
  },
  {
    path: '/contactus',
    name: 'Contact Us',
    component: () => import('../views/ContactUs.vue')
  },
  {
    path: '/document-summarizer',
    name: 'Document Summarizer',
    component: () => import('../views/DocumentSummarizer.vue')
  },
  {
    path: '/conversation-summarizer',
    name: 'Conversation Summarizer',
    component: () => import('../views/ConversationSummarizer.vue')
  },
  {
    path: '/pls-test',
    name: 'Plain language summaries - Test',
    component: () => import('../views/PlainLanguageSummaries.vue')
  },
  {
    path: '/pls',
    name: 'Plain language summaries',
    component: () => import('../views/PlsWizard.vue')
  },
  {
    path: '/plspackage',
    name: 'Plain language summaries Package',
    component: () => import('../views/PlsPackage.vue')
  },
  {
    path: '/case-studies',
    name: 'Case Studies',
    component: () => import('../views/CaseStudies.vue')
  },
  {
    path: '/case-studies/final-grade-prediction',
    name: 'Final Grade Prediction',
    component: () => import('../views/FinalGradePrediction.vue')
  },
  {
    path: '/case-studies/essay-grader',
    name: 'Essay Grader',
    component: () => import('../views/EssayGrader.vue')
  },
  {
    path: '/case-studies/plain-language-summary',
    name: 'Plain language summary',
    component: () => import('../views/PlsCaseStudy.vue')
  },
  {
    path: '/ai-ml-projects',
    name: 'AI ml projects',
    component: () => import('../views/AtimsoftAiMlProject.vue')
  },
  {
    path: '/ai-interview',
    name: 'AI interview',
    component: () => import('../views/AI-interview-first-page.vue')
  },
  {
    path: '/ai-interview/:id',
    name: 'AI Interview Page for Candidate',
    component: () => import('../views/AI-interview.vue')
  },
  {
    path: '/ai-interview/:id/report',
    name: 'AI interview report',
    component: () => import('../views/AI-interview-report.vue')
  },
  {
    path: '/case-studies/ai-interview',
    name: 'AI interview case study',
    component: () => import('../views/AI-interview-case-study.vue')
  },
  {
    path: '/case-studies/ai-airport-safety',
    name: 'AI-Based Analytics For Airport Safety',
    component: () => import('../views/AI-airport-safety-case-study.vue')
  },
  {
    path: '/gdp/:id',
    name: 'IA Grader',
    component: () => import('@/views/gdp')
  }
]
const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
