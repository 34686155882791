<template>
  <div class="AppPage">
    <header class="AppPage__header">
      <slot name="header" />
    </header>
    <main class="AppPage__main">
      <slot name="main" />
    </main>
    <footer class="AppPage__footer">
      <slot name="footer" />
    </footer>
  </div>
</template>

<style lang="scss" >
@media (min-width: 1200px) {
    .container-xl, .container-lg, .container-md, .container-sm, .container {
        max-width: 1140px !important;
    }
}
body {
    margin: 0 !important;
    font-family: "Rubik", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;
    font-size: 1rem !important;
    font-weight: 400 !important;
    line-height: 1.5 !important;
    color: #495057 !important;
    text-align: left !important;
    background-color: #f8f9fa !important;
}
$white: #fff;

.AppPage {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
  &__header {
    position: sticky;
    top: 0;
    background-color: $white;
    box-shadow: 4px 0 24px rgba(35, 91, 168, 0.18);
    z-index: 4;
  }

  &__main {
    flex: 1;
    background-color: var(--main-background-color, rgba(28, 117, 212, 0.02));
    overflow-x: auto;
  }

  &__footer {
    background: $white;
  }
}
</style>
