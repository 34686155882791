<template>
  <div class="navbar-container">
    <div class="bg-dark navbar-dark" data-sticky="top">
      <div class="navbar_banner navbarp" style="background-color: blue; text-align: center;">
        <div class="col-12 navbar_banner_text-wrapper w-inline-block justify-content-between">
          Unlock two months of FREE consulting and development for all new AI projects with us. 
        </div>   
      </div>
      <div class="container">
        <nav class="navbar navbar-expand-lg">
          <a class="navbar-brand" href="/">
            <span id="atimsoft-logo">ATIMSOFT</span>
          </a>
          <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <i class="icon-menu h4"></i>
          </button>
          <div class="collapse navbar-collapse justify-content-between" id="navbarNav">
            <ul class="navbar-nav">
              <li class="nav-item">
                <a href="/#services" class="nav-link">Services</a>
              </li>
              <li class="nav-item">
                <a href="/#clients" class="nav-link">Clients</a>
              </li>
              <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  AI Demos
                </a>
                <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                  <router-link to="/bar-exam-prep-assistant" class="dropdown-item">Bar Exam Prep Assistant</router-link>
                  <router-link to="/ae-classifier" class="dropdown-item">AE Classifier</router-link>
                  <router-link to="/yt_summarizer" class="dropdown-item">Youtube Summarizer</router-link>
                  <router-link to="/document-summarizer" class="dropdown-item" style="display: none;">Document Summarizer</router-link>
                  <router-link to="/pls" class="dropdown-item">Plain Language Summaries</router-link>
                </div>
              </li>
              <li class="nav-item">
                <a href="/case-studies" class="nav-link">AI Case Studies</a>
              </li>
              <!--li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" href="/case-studies" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  AI Case Studies
                </a>
                <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                  <router-link to="/case-studies/final-grade-prediction" class="dropdown-item">Final Grade Prediction</router-link>
                  <router-link to="/case-studies/essay-grader" class="dropdown-item">Essay Grader</router-link>
                  <router-link to="/case-studies/plain-language-summary" class="dropdown-item">Plain Language Summary</router-link>
                  <router-link to="/case-studies/ai-interview" class="dropdown-item">AI Interviewer</router-link>
                </div>
              </li-->
            </ul>
            <ul class="navbar-nav">
              <li class="nav-item d-flex align-items-center">
                <router-link to="/contactus" class="btn btn-success">Contact us</router-link>
              </li>
            </ul>

          </div>
          <!--end nav collapse-->
        </nav>
      </div>
      <!--end of container-->
    </div>
  </div>
</template>
<style>
.navbarp {
  padding: 0.4rem;
}
</style>
