
import { defineComponent, ref } from 'vue'

const contactLink = 'https://barbri.com/contact'

export default defineComponent({
  setup() {
    const currentYear = ref(new Date().getFullYear())

    return {
      contactLink,
      currentYear
    }
  }
})
